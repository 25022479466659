export class Regional {
  constructor ({
    id,
    nome,
    estados,
  } = {
    id: '',
    nome: '',
    estados: [],
  }) {
    this.id = id
    this.nome = nome
    this.estados = estados
  }
}

export function createRegional (data) {
  return Object.freeze(new Regional(data))
}
