import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Regional } from '@/models/Regional'
import Swal from 'sweetalert2'
import { regionalTransformer } from '@/utils/transformers'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    estados: [],
    loading: true,
    isEditing: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit('SET_STATE', { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Regional() }, { root: true })
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('regional', id).then(response => {
        commit(SET_STATE, { loading: false })
        dispatch('form/BOOTSTRAP_FORM', { model: new Regional(regionalTransformer(response.data[0])) }, { root: true })
      })
    } else {
      commit(SET_STATE, { loading: false, isEditing: false })
    }
  },

  async [SUBMIT] ({ commit, dispatch, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Cadastrando Regional!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        const form = { ...rootState.form.data };
        (() => {
          commit('SET_STATE', { loading: true })
          return state.isEditing
            ? api.salvarEntidade(form, 'regional', form.id)
            : api.cadastrarEntidade(form, 'regional')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Regional atualizada com sucesso!'
                : 'Regional cadastrada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch('regionais/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/regionais`,
            })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
